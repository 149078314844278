import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import contents from "../../contents/apps.json";
import {Helmet} from "react-helmet";

const Apps = () => {
    return (
        <div className="row">
            <div className={'col-12 col-lg-4 pb-5 pb-lg-0'}>
                <StaticImage
                    src="../../images/services/app.png"
                    alt="Apps"
                    placeholder="tracedSVG"
                    layout="constrained"
                    imgClassName="image"
                    objectFit={'contain'}
                    width={511}
                    height={458}
                    quality={100}
                />
            </div>
            <div className={'col-12 col-lg-8 ps-lg-5'}>
                <h2 className={'level4'}>Apps developed to perfection</h2>

                <div className="accordion" id="apps-accordion">

                    {contents.map((content, index) => {
                        return (
                            <div key={index} className="accordion-item">
                                <h3 className="accordion-header" id={'apps-'+index}>
                                    <button className={index === 0 ? 'accordion-button' : 'accordion-button collapsed'} type="button"
                                            data-bs-toggle="collapse" data-bs-target={'#apps-collapse-'+index}
                                            aria-expanded={index === 0 ? true : false} aria-controls={'apps-collapse-'+index}>
                                        {content.title}
                                    </button>
                                </h3>
                                <div id={'apps-collapse-'+index} className={index === 0 ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'}
                                     aria-labelledby={'apps-'+index}
                                     data-bs-parent="#apps-accordion">
                                    <div className="accordion-body">
                                        {content.text}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default Apps
