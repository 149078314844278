import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header";
import Footer from "../components/footer";
import Branding from "../components/services/branding";
import Web from "../components/services/web";
import Apps from "../components/services/apps";
import Video from "../components/services/video";
import {Helmet} from "react-helmet";
import {useState} from "react";


const Services = () => {
    const [title,setTitle] = useState('Services | Design, development and communication')
    return (
        <main>
            <Helmet>
                <title>Designatives - {title}</title>
                <meta name="description" content={"We do a bunch of things in the digital field, web design, web development, print design, logo & branding, mobile application development (iOS & Android)"}/>
            </Helmet>
            <Header/>
            <Layout>
                <div className={'services-subpage'}>
                    <section className={'services-section'}>
                        <div className={'container'}>

                            <div className="row">
                                <div className={'col-12 col-lg-8 offset-lg-4 ps-lg-5'}>
                                    <ul className="nav nav-pills" id="pills-tab">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" data-text={'Branding'} id="pills-branding-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-branding" type="button" role="tab"
                                                    onClick={()=>setTitle('Branding concepts enchanted by design')}
                                                    aria-controls="pills-branding" aria-selected="true">Branding
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" data-text={'Web'} id="pills-web-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-web" type="button" role="tab"
                                                    onClick={()=>setTitle('Beautiful websites built for performance')}
                                                    aria-controls="pills-web" aria-selected="false">Web
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" data-text={'Apps'} id="pills-apps-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-apps" type="button" role="tab"
                                                    onClick={()=>setTitle('Apps developed to perfection')}
                                                    aria-controls="pills-apps" aria-selected="false">Apps
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" data-text={'Video'} id="pills-video-tab" data-bs-toggle="pill"
                                                    data-bs-target="#pills-video" type="button" role="tab"
                                                    onClick={()=>setTitle('Animated and filmed video production')}
                                                    aria-controls="pills-video" aria-selected="false">Video
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-branding" role="tabpanel"
                                     aria-labelledby="pills-branding-tab">
                                    <Branding/>
                                </div>
                                <div className="tab-pane fade" id="pills-web" role="tabpanel"
                                     aria-labelledby="pills-web-tab">
                                    <Web/>
                                </div>
                                <div className="tab-pane fade" id="pills-apps" role="tabpanel"
                                     aria-labelledby="pills-apps-tab">
                                    <Apps/>
                                </div>
                                <div className="tab-pane fade" id="pills-video" role="tabpanel"
                                     aria-labelledby="pills-video-tab">
                                    <Video/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </Layout>
            <Footer/>
        </main>
    )
}

export default Services
